const path = require("path");

var digigovCssInfo = require(path.join(__dirname,'./package.json'))
const digigovTailwindConfig = require(path.join(__dirname,'./tailwind.config.js'));

const base = require(path.join(__dirname,'./dist/base'))
const utilities = require(path.join(__dirname,'./dist/utilities'))
const components = require(path.join(__dirname,'./dist/components'))

const mainFunction = ({ addBase, addComponents, addUtilities, config }) => {
	let digigovCssIncludedItems = []
	let logs = false
	if (config('digigovCss.logs') != false) {
		logs = true
	}
	if (logs) {
		console.log()
		console.log('\x1b[35m%s\x1b[0m', '🌼 Digigov CSS components ' + digigovCssInfo.version, '\x1b[0m', digigovCssInfo.homepage)
		console.group()
	}

	// inject @base style 
	if (config('digigovCss.base') != false) {
		addBase(base)
		digigovCssIncludedItems.push('base')
	}

	// inject components
	let classNames = [];
	let mediaQueries = [];
	// split compiled components in two different sectors
	// to load them in the correct order, since media queries
	// should be always after the main CSS rules
	Object.keys(components).forEach(selector => {
		// checking if selectors are media queries
		if (selector.startsWith('@media')) {
			mediaQueries.push({[selector]: components[selector]});
		// ... or just regular css rules
		} else {
			classNames.push({[selector]: components[selector]});
		}
	});
	if (config('digigovCss.components') != false) {
		// this order is important for loading CSS rules and media queries
		// consistently, since the keys in js objects are not ordered and 
		// sometimes order can be different. By spreading them in sequence 
		// we fix this bug
		addComponents([
			...classNames,
			...mediaQueries,
		])
		digigovCssIncludedItems.push('components')
	}

	// inject @utilities style needed by components
	if (config('digigovCss.utils') != false) {
		addUtilities(utilities)
		digigovCssIncludedItems.push('utilities')
	}
	if (logs) {
		console.log('\x1b[32m%s\x1b[0m', '✔︎ Including:', '\x1b[0m', '' + digigovCssIncludedItems.join(', '));
		if (isTailwindInstalled === false) {
			console.log(`\n\x1b[33;1m! warning\x1b[0m - unable to require \x1b[36mtailwindcss/plugin\x1b[0m
Digigov CSS color are now only available for Digigov CSS components.
If you want to use DigigovCSS color as utility classes (like 'bg-primary')
you need to add this to your \x1b[36mtailwind.config.js\x1b[0m file:
───────────────────────────────────────
\x1b[36mmodule.exports = {
  \x1b[32mtheme: {
    extend: {
      colors: require('@digigov/css/colors'),
    },
  },\x1b[0m
\x1b[36m}\x1b[0m
───────────────────────────────────────  
      `)
		}
		console.log()
		console.groupEnd()
	}
}

// check if tailwindcss package exists
let isTailwindInstalled = false;
try {
	require.resolve('tailwindcss/plugin')
	isTailwindInstalled = true
} catch (er) {
	isTailwindInstalled = false
}
if (isTailwindInstalled !== false) {
	module.exports = require("tailwindcss/plugin")(
		mainFunction, digigovTailwindConfig
	);
} else {
	module.exports = mainFunction;
}
